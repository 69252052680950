import { EventBus } from 'EventBus'

export default {
    data: () => ({
        reloadFnName: 'init_component'
    }),
    created() {
        EventBus.$on('sync:progress_success', this.onSyncSuccess)
    },
    destroyed() {
        EventBus.$off('sync:progress_success', this.onSyncSuccess)
    },
    methods: {
        onSyncSuccess(modified) {
            if (modified) {
                const reloadFn = this[this.reloadFnName]
                if (reloadFn) {
                    reloadFn()
                }
            }
        }
    }
}