<template>
    <div>        
        <b-button id="popover-notifications" v-if="!isBelowMd || (isBelowMd && isNavNotificationsOpen)">
            <div
                v-if="notifications.length > 0"
                @click="onNotificationBellClick"
                class="d-none d-md-block notifications-button"
            >
                <NotificationsBell/>
            </div>
        </b-button>

        <b-button v-if="!isBelowMd">
            <div
                id="read-all-notifications"
                v-if="notifications.length > 0 && isNavNotificationsOpen"
                @click.stop="readAllNotifications"
                class="d-none d-md-block read-all-notifications-button"
            >
                <font-awesome-icon
                    :icon="['fal', 'eye-slash']"
                    class="notif-close ml-auto"
                />
            </div>
        </b-button>

        <b-popover
            target="popover-notifications"
            custom-class="popNotif"
            triggers="manual"
            boundary="viewport"
            placement="top"
            :show="isNavNotificationsOpen"
        >
            <h2 class="d-md-none">{{ $t('global.notifications') }}</h2>

            <h5 v-if="notifications.length === 0">
                {{ $t('global.aucune_notification') }}
            </h5>

            <div class="text-center">
                <b-button 
                    id="read-all-notifications"
                    v-if="notifications.length > 0 && isNavNotificationsOpen"
                    @click.stop="readAllNotifications"
                    class="d-md-none mb-4"
                >
                    <h4>{{ $t('notification.mark_all_read')}}</h4>
                    <font-awesome-icon
                    :icon="['fal', 'eye-slash']"
                    class="notif-close ml-auto"
                />
                </b-button>
            </div>

            <div
                v-for="notification in notifications" 
                :key="notification.notification_id"
                class="box"
                @click="onNotificationClick(notification)"
            >
                <h4 class="d-flex align-items-center">
                    {{ notification.notification_title }}
                    <font-awesome-icon
						:id="'eye-option-'+notification.notification_id"
                        :icon="['fal', 'eye-slash']"
                        class="notif-close ml-auto"
                        @click.stop='readNotification(notification.notification_id)'
                    />
                </h4>
                <div>
                    <b>{{ notification.notification_date_formatted }}</b><br/>
                    <i>{{ notification.notification_content }}</i>
                </div>

				<b-popover 
					:target="'eye-option-'+notification.notification_id"
					triggers="hover"
            		placement="top"
				>
					{{ $t('notification.mark_read') }}
				</b-popover>
            </div>
            <b-popover 
                target="read-all-notifications"
                triggers="hover"
                placement="top"
            >
                {{ $t('notification.mark_all_read') }}
            </b-popover>
        </b-popover>
    </div>
</template>

<script>
import Notification from '@/mixins/Notification.js'
import SyncWatcher from '@/mixins/SyncWatcher.js'
import Window from '@/mixins/Window.js'

export default {
    name: 'Notifications',
    mixins: [Notification, SyncWatcher, Window],
    components: {
        NotificationsBell: () => import('@/components/Header/NotificationsBell.vue')
    },
    data: () => ({
        notifications: []
    }),
    computed: {
        isNavNotificationsOpen() {
            return this.$store.state.nav.isNotificationsOpen
        }
    },
    created() {
        this.init_component()
    },
    methods: {
        async init_component() {
            this.notifications = await this.loadNotifications()
            this.$store.commit('nav/updateNotificationsCount', this.notifications.length)
        },
        onNotificationClick(notification) {
            this.$store.commit('nav/closeNotifications')

            this.$router.push({
                name: 'notificationHandler',
                params: {
					notif_id: notification.notification_id,
                    action: notification.notification_action,
                    params: JSON.stringify(notification.notification_params)
                }
            })
        },
        onNotificationBellClick() {
            if (this.isNavNotificationsOpen) {
                this.$store.commit('nav/closeNotifications')
            }
            else {
                this.$store.commit('nav/openNotifications')
            }
        },
		readNotification(notif_id) {
			this.markNotificationAsRead(notif_id)
		},
		readAllNotifications() {
            for(const notification of this.notifications){
                this.markNotificationAsRead(notification.notification_id)
            }
		}
    },
    watch:{
        'notifications.length' (val) {
            if(val == 0 && this.isNavNotificationsOpen) {
                this.$store.commit('nav/closeNotifications')
            }
        }
    }
}
</script>
