import Vue from 'vue'
import Transformer from './Transformer'

export default class NotificationTransformer extends Transformer {

    table = 'notifications'

    async transform (notification) {
        const notification_date = Date.parseTz(notification.notification_date)
        return {
            notification_id: notification.notification_id,
            notification_date: notification_date,
            notification_date_formatted: notification_date.toLocaleDateString(Vue.i18n.locale()),
            notification_title: notification.notification_title,
            notification_content: notification.notification_content,
            notification_icon: notification.notification_icon,
            notification_action: notification.notification_action,
            notification_acknowledged: notification.notification_acknowledged,
            notification_params: notification.notification_params,
        }
    }
}
