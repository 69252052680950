import NotificationTransformer from '@/assets/js/dexie/transformers/NotificationTransformer.js'

export default {
    methods: {
        loadNotifications() {
            return this.$storage.db.t('notifications')
            .then(table => {
                return table.where('notification_acknowledged').equals(0)
            })
            .then(col => {
                return col.transform(new NotificationTransformer())
            })
		},
		
		getNotification(notif_id) {
			return this.$storage.db.t('notifications')
			.then(table => {
                return table.where('notification_id').equals(parseInt(notif_id))
            })
            .then(col => {
                return col.transform(new NotificationTransformer())
            })
		},

        async markNotificationAsRead(notification_id) {
			const notif = await this.getNotification(notification_id)

			// Si la notif n'existe pas, on force une synchro pour le modèle notifications
			if(!notif || notif.length === 0) {
				await this.$sync.runRequiredWhishlist(['notifications'])
			}

            return this.$storage.db.t('notifications')
            .then(table => {
                return table.update(parseInt(notification_id), {
                    notification_acknowledged: 1
                })
			})
			.then(() => {
				this.refreshNotifCount()
			})
		},
		
		async refreshNotifCount() {
			this.notifications = await this.loadNotifications()
            this.$store.commit('nav/updateNotificationsCount', this.notifications.length)
		}
    }
}
